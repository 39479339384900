<template>
  <div class="container">
    <h2 class="my-5 text-center">About Parkwood</h2>
    <div class="col-xs-10 col-md-8 mb-2 mx-auto d-block bringup">
      <p>The Parkwood community consists of Parkwood Estates, Parkwood Ridge and two nearby properties. Parkwood Estates has several distinctive features, including underground utilities, street gutters, lantern lights in front of most homes, a maintained entry way, and laurel hedges around its southern and western sides. Parkwood Ridge, to the east of Parkwood Estates, is adjacent to natural areas preserved by the City of Mercer Island.</p>
      <p>Parkwood Club, Inc. is the homeowners' association for the Parkwood community. It maintains common areas, enhances community awareness and monitors compliance with the protective covenants for Parkwood Estates.</p>
    </div>
      <div class="row">
        <div class="container-fluid mx-center">
          <div class="col-12">
            <h6 style="text-align: center;">
              <a href="https://www.google.com/maps/@47.552766,-122.219832,16z?hl=en-US">
                <font-awesome-icon icon="link" class="mr-2" />Location
              </a>
            </h6>
            <iframe
              style="margin: 0 auto; display: block;"
              height="350"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/?ie=UTF8&amp;ll=47.552766,-122.219832&amp;spn=0.012194,0.023088&amp;t=m&amp;z=16&amp;output=embed"
            ></iframe>
            <br />
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "About",
};
</script>

<style lang="scss" scoped>
h4.title {
  color: darkgreen;
  font-size: 1.3em;
  text-align: center;
}
.bringup {
  margin-top: -40px;
}
</style>