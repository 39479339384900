<template>
  <div class="container">
    <h4 class="centered">Parkwood Club Inc. Board (2024-25)</h4>
    <p class="centered smaller"><a href="/members">Members</a></p>
    <p class="centered smaller"><a href="/associates">Associate Members</a></p>
    <p class="centered smaller">
      Return to <a href="/member-info">Member Information</a>
    </p>
    <table class="fullsize">
      <tbody class="officers">
        <tr>
          <th class="office heading">Office</th>
          <th class="name heading">Name</th>
          <th class="phone heading">Phone</th>
          <th class="email heading">Email</th>
        </tr>
        <tr
          class="whitebg"
          v-for="officers in $options.officers"
          :key="officers.id"
        >
          <td class="office">{{ officers.office }}</td>
          <td class="name">{{ officers.name }}</td>
          <td class="phone">{{ officers.phone }}</td>
          <td class="email">
            <a :href="`mailto:${officers.email}`">{{ officers.email }}</a>
          </td>
        </tr>
      </tbody>
    </table>
    <p>&nbsp;</p>
  </div>
</template>

<script>
import json from ".././json/officers.json";
export default {
  name: "officers",
  officers: json,
};
</script>

<style lang="scss" scoped>
tbody.officers {
  background-color: darkgreen;
  padding: 12px;
  margin: 0 auto;
}
h4.centered {
  text-align: center;
}

table.fullsize {
  width: 100%;
  border: 1px solid darkgreen;
}
th.heading {
  text-align: left;
  color: white;
  padding-bottom: 1.3em;
}
tr.whitebg {
  background-color: white;
  line-height: 2em;
}
tr:nth-child(even) {
  background-color: #d0f0c0;
}
td.office {
  width: 15%;
  font-weight: bold;
  color: darkgreen;
  font-size: 1.1em;
  text-indent: 0.3em;
}
td.name {
  width: 17%;
}
td.phone {
  width: 15%;
}
td.email {
  width: 25%;
}
p.smaller {
  font-size: 0.8em;
  text-align: right;
}
</style>
