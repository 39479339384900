<template>
<div class="container">
  <div class="hero text-center">
    <img
      class="mb-0 mx-auto d-none d-sm-block col-xs-4 col-sm-6 col-md-8"
      src="https://s3-us-west-2.amazonaws.com/parkwoodhomes.org/parkwood_v2-logo-small.png"
      alt="Parkwood logo"
    />
    <div class="lead"></div>
  </div>
  </div>
</template>

<script>
export default {
  name: "Hero",
};
</script>

<style scoped>
.hero {
  background-color: rgb(232, 245, 232);
  border-radius: 25px;
  margin: 0 auto;
  max-width: 40%;
  max-height: 40%;
}

</style>
