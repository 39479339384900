<script>
import SubHero from "../components/SubHero";
import ServiceProviders from "../components/ServiceProviders";
export default {
  name: "service-providers",
  components: {
    SubHero,
    ServiceProviders,
  },
};
</script>

<template>
  <div>
    <SubHero />
    <hr />
    <ServiceProviders />
  </div>
</template>


<style lang="scss" scoped>
.parkwood {
  .fa-link {
    margin-right: 5px;
  }
}
</style>