<template>
  <div>
    <Hero />
    <hr />
      <div v-if="$auth.isAuthenticated">
        <Welcome/>
      </div>
     <div v-if="!$auth.isAuthenticated">
    <HomeContent />
     </div>
  </div>
</template>

<script>
import Hero from "../components/Hero";
import HomeContent from "../components/HomeContent";
import Welcome from "../components/Welcome";

export default {
  name: "home",
  components: {
    Hero,
    HomeContent,
    Welcome
  },
};
</script>

<style lang="scss" scoped>
.parkwood {
  .fa-link {
    margin-right: 5px;
  }
}
</style>
