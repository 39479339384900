<style scoped>
.container {
  text-align: center;
}
</style>

<template>
  <div class="container">
    <div class="row">
      <p
        class="mx-md-auto"
        style="font-size: 1.4em; font-weight: bold; color: darkgreen; text-align: center;"
      >Is there a service provider you can recommend to Parkwood members?</p>
      <p
        class="mx-md-auto"
        style="font-size: 1.2em; font-weight: bold; color: darkgreen; text-align: center;"
      >
        <span style=" font-style: italic;">
          <a href="/service-providers">Click here</a>
        </span> to see a list of recommendations made so far.
      </p>
      <p
        class="mx-md-auto"
        style="width: 70% font-style: italic; font-weight: bold;"
      >To add to the list, please complete this form. We will let you now when it's posted or if there is any follow-up is needed before it can be. Thanks!</p>
    </div>

    <form
      class="row form"
      id="service-providers"
      role="form"
      method="POST"
      @submit.prevent="onSubmit"
    >
      <div class="container">
        <div class="form-group row">
          <div class="col-xs-10 col-md-6 mx-md-auto mx-md-auto">
            <label for="mName">MEMBER NAME (first & last)</label>
            <input type="text" v-model="mName" name="mName" id="mName" class="form-control" />
          </div>
          <div class="col-xs-10 col-md-6 mx-md-auto">
            <label for="mContact">MEMBER CONTACT INFO (email or cell #)</label>
            <input
              type="text"
              v-model="mContact"
              name="mContact"
              id="mContact"
              class="form-control"
            />
          </div>
        </div>
      </div>

      <div class="form-group row" style="margin: 0px auto; width=50%; padding-bottom: 20px;">
        <div class="col-xs-10 col-md-12 mx-md-auto">
          <label for="sCategory">SERVICE CATEGORY:&nbsp;&nbsp;&nbsp;</label>
          <select v-model="selected">
            <option
              disabled
              value="Please select one"
            >Please select the most appropriate category ...</option>
            <option>Outdoor - tree, lawn, garden, etc.</option>
            <option>Exterior - painting, windows, roofing, etc.</option>
            <option>Interior - electrical, plumbing, housekeeping, etc.</option>
            <option>Design - renovation, remodeling, etc.</option>
            <option>Other - anything you'd consider valuable for a friend!</option>
          </select>
        </div>
      </div>

      <div style="padding-top: 6px;" class="container-fluid">
        <div class="form-group row">
          <div class="col-xs-10 col-md-6 mx-md-auto">
            <label for="sName">SERVICE PROVIDER NAME (individual or business)</label>
            <input type="text" v-model="sName" name="sName" id="sName" class="form-control" />
          </div>
          <div class="col-xs-10 col-md-6 mx-md-auto">
            <label
              for="sContactInfo"
            >SERVICE PROVIDER CONTACT (email, phone, website)</label>
            <input
              type="text"
              v-model="sContactInfo"
              name="sContactInfo"
              id="sContactInfo"
              class="form-control"
            />
          </div>
        </div>
      </div>

      <div style="padding-top: 6px;" class="container-fluid">
        <div class="form-group row">
          <div class="col-xs-10 col-md-12 mx-md-auto">
            <label
              for="mComments"
            >MEMBER COMMENTS (briefly describe prior work, considerations, outcome, etc.)</label>
            <textarea
              class="form-control"
              v-model="mComments"
              name="mComments"
              id="mComments"
              placeholder
              rows="2"
            />
          </div>
        </div>
      </div>
      <!--LOADING MESSAGE-->
      <div v-if="loadingTxt">
        <p class="mb-8 text-primary">Delivering your message...</p>
      </div>
      <div v-if="msgSent">
        <p class="mb-8 text-primary">Message sent!</p>
      </div>
      <div class="col-sm-12 mx-md-auto">
        <button
          type="submit"
          class="btn btn-primary mx-auto d-block"
          @click="onSubmit()"
          value="Submit"
        >Send</button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ServicesForm",
  data() {
    return {
      sCategory: {
        selected: "",
      },
      mName: "",
      mContact: "",
      sName: "",
      sContactInfo: "",
      mComments: "",
      loadingTxt: false,
      msgSent: false,
    };
  },
  methods: {
    onSubmit() {
      this.loadingTxt = true;
      axios
        .post("https://formspree.io/xnqgllla", {
          _subject: `Service Provider Recommendation for Parkwood website`,
          Member_Name: this.mName,
          Member_Contact: this.mContact,
          Service_Provider_Name: this.sName,
          Service_Category: this.selected,
          Service_Contact_Info: this.sContactInfo,
          Member_Comments: this.mComments,
        })
        .then(() => {
          return ((this.mName = ""),
          (this.mContact = ""),
          (this.sName = ""),
          (this.selected = ""),
          (this.sContactInfo = ""),
          (this.mComments = ""),
          (this.loadingTxt = false),
          (this.msgSent = true)).catch((response) => {
            if (!response) {
              // eslint-disable-next-line no-alert
              alert("did not go thru!"); // => the response payload  }
            }
          });
        });
    },
  },
};
</script>

<style scoped>
label {
  font-weight: bold;
}
input,
select,
textarea {
  background-color: #3131;
}
</style>
