<template>
  <div>
    <Hero />
    <hr />
    <AddSubscriber />
    <br/>
  </div>
</template>

<script>
import Hero from "../components/Hero";
import AddSubscriber from "../components/AddSubscriber";

export default {
  name: "add-subscriber",
  components: {
    Hero,
    AddSubscriber
  }
};
</script>

<style lang="scss" scoped>
.parkwood {
  .fa-link {
    margin-right: 5;
  }
}
textarea { resize:both; }
</style>
