<template>
  <div class="container">
    <div class="col-xs-10 col-md-8 mb-2 mx-auto d-block bringup">
      <h3 class="title">Welcome {{ $auth.user.name }}</h3>
      <h4 class="welcome"><a href="/member-info">Member Information</a></h4>
    </div>
  </div>
</template>

<script>
export default {
  name: "Welcome",
};
</script>

<style lang="scss" scoped>
h3.title {
  color: darkgreen;
  font-size: 1.3em;
  text-align: center;
}
h4.welcome {
  color: blue;
  font-size: 1.2em;
  text-align: center;
}
.bringup {
  margin-top: -10px;
}
</style>
