<template>
  <div>
    <SubHero />
    <hr />
    <MemberInfo />
      <div class="col-md-4 mb-4 d-inline">
     <h3 class="nextdoor text-center">Stay in touch with Parkwood neighbors in our <a href="https://nextdoor.com/g/6cybyf6kz/">private group on Nextdoor.com</a></h3>
    </div>


  </div>
</template>

<script>
import SubHero from "../components/SubHero";
import MemberInfo from "../components/MemberInfo";

export default {
  name: "extra",
  components: {
    SubHero,
    MemberInfo
  }
};
</script>

<style lang="scss" scoped>
  h3.nextdoor {
    color: darkgreen;
    font-weight: bold;
    font-style: italic;
    text-align: center;
    font-size: 1.2em;
    font-variant:small-caps;
    vertical-align: bottom;
}
</style>
