
<template>
<div class="container">
  <div class="row">
    <p class="mx-md-auto" style="font-size: 1.2em; font-weight: bold; color: darkgreen;">Submit this form to contact Parkwood Club, Inc.</p>
      <div class="col-xs-10">
        <form name="contact-us" class="row form" method="POST" netlify-honeypot="bot-field" data-netlify-recaptcha="true" v-on:submit.prevent="handleSubmit" action="/sent">
        <p class="hidden">
            <label>fill out if a bot: <input name="bot-field" /></label>
        </p>
          <div class="col-xs-10 col-md-6">
            <div class="form-group">
                <label for="fname">First Name</label>
                <input class="form-control" type="text" name="fname" id="fname" v-model="formData.fname" placeholder="e.g. John" required="">
            </div>
        </div>
        <div class="col-xs-10 col-md-6">
            <div class="form-group">
                <label for="lname">Last Name</label>
                <input class="form-control" type="text" name="lname" id="lname" v-model="formData.lname" placeholder="e.g., Doe" required="">
            </div>
        </div>            
        <div class="col-xs-10 col-md-6">
            <div class="form-group">
                <label for="email">Email</label>
                <input class="form-control" type="email" name="email" id="email" v-model="formData.email" placeholder="someone@example.com" required="">
            </div>
        </div>
        <div class="col-xs-10 col-md-6 mx-md-auto">
            <div class="form-group">
                <label for="subject">Subject</label>
                <input class="form-control"  type="text" name="subject" id="subject"  v-model="formData.subject" placeholder="e.g., My inquiry is about ..." required="">
            </div>
        </div>
        <div class="col-xs-10 col-md-12 mx-md-auto">
            <div class="form-group">
                <label for="message">Message</label>
                <textarea class="form-control" name="message" id="message"  v-model="formData.message" placeholder="" required="" cols="20" rows="4" />
            </div>
        </div>
        <div class="col-xs-8 col-md-4 mx-md-auto">
          <div data-netlify-recaptcha="true"></div>
          <button color="primary" type="submit" class="btn btn-primary mx-auto d-block">Send</button>
        </div>
        </form>
    </div>
</div>
</div>

</template>

<script>
export default {
  name: "ContactForm",
  data() {
    return {
      formData: {},
    };
  },
  methods: {
    encode(data) {
      return Object.keys(data)
        .map(
          key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
    },
    handleSubmit(e) {
      fetch("/sent", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: this.encode({
          "form-name": e.target.getAttribute("name"),
          ...this.formData,
        }),
      })
        .then(() => this.$router.push("sent"))
        .catch(err => alert(err));
    },
  },
};
</script>

<style scoped>
label {
  font-weight: bold;
}
input,
select,
textarea {
  background-color: #3131;
}
p.hidden {
   display: none;
}
</style>