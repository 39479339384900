<template>
  <div>
    <hero />
    <hr />
    <about-content />
  </div>
</template>

<script>
import Hero from "../components/Hero";
import AboutContent from "../components/AboutContent";

export default {
  name: "about",
  components: {
    Hero,
    AboutContent,
  },
};
</script>

<style lang="scss" scoped>
.parkwood {
  .fa-link {
    margin-right: 5px;
  }
}
</style>
