<script>
import SubHero from "../components/SubHero";
import AssociateDirectory from "../components/AssociateDirectory";
// import members from '.././json/members.json'
export default {
  name: "associates",
  components: {
    SubHero,
    AssociateDirectory,
  },
};
</script>

<template>
  <div>
    <SubHero />
    <hr />
    <AssociateDirectory />
  </div>
</template>


<style lang="scss" scoped>
.parkwood {
  .fa-link {
    margin-right: 5px;
  }
}
</style>