import Vue from "vue";
import Router from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import ContactForm from "../views/ContactForm.vue";
import MemberInfo from "../views/MemberInfo.vue";

import MemberDirectory from "../views/MemberDirectory.vue";
import AssociateDirectory from "../views/AssociateDirectory.vue";
import Officers from "../views/Officers.vue";
import ServiceProviders from "../views/ServiceProviders.vue";

import AddSubscriber from "../views/AddSubscriber.vue";
import ServicesForm from "../views/ServicesForm.vue";

import Profile from "../views/Profile.vue";
import { authGuard } from "../auth";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/about",
      name: "about",
      component: About
    },
    {
      path: "/contact-us",
      name: "contact-us",
      component: ContactForm
    },
    {
      path: "/add-subscriber",
      name: "add-subscriber",
      component: AddSubscriber
    },
    {
      path: "/member-info",
      name: "member-info",
      component: MemberInfo,
      beforeEnter: authGuard
    },
    {
      path: "/members",
      name: "member-directory",
      component: MemberDirectory,
      beforeEnter: authGuard
    },
    {
      path: "/officers",
      name: "officers",
      component: Officers,
      beforeEnter: authGuard
    },
    {
      path: "/service-providers",
      name: "service-providers",
      component: ServiceProviders,
      beforeEnter: authGuard
    },
    {
      path: "/associates",
      name: "associate-directory",
      component: AssociateDirectory,
      beforeEnter: authGuard
    },
    {
      path: "/profile",
      name: "profile",
      component: Profile,
      beforeEnter: authGuard
    },
    {
      path: "/sent",
      name: "sent",
      component: () => import("../views/FormSent.vue")
    },
    {
      path: "/notSent",
      name: "notSent",
      component: () => import("../views/FormNotSent.vue")
    },
    {
      path: "/services-form",
      name: "services-form",
      component: ServicesForm,
    },
    {
      path: "/*",
      name: "notFound",
      component: () => import("../views/NotFound.vue")
    }
  ]
});

export default router;
