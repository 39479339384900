<template>
  <div class="container">
    <h4 class="centered">Service Provider Recommendations</h4>
    <p class="centered smaller">
      Back to
      <a href="/member-info">Member Information</a>
    </p>
    <table class="providers">
      <tbody class="sproviders">
        <tr class="sp-heading">
          <th class="sp-name">Service Provider (SP)</th>
          <th class="service-category">SP Category</th>
          <th class="contact-info">SP Contact Info</th>
          <th class="member-name">Member name & Contact Info</th>
          <th class="member-comments">Member comments</th>
        </tr>
        <tr
          class="whitebg msInput"
          v-for="serviceproviders in $options.serviceproviders"
          :key="serviceproviders.id"
        >
          <td class="sName">{{serviceproviders.sName}},</td>
          <td class="sCategory">{{serviceproviders.sCategory}}</td>
          <td class="sContactInfo">{{serviceproviders.sContactInfo}}</td>
          <td class="mName">{{serviceproviders.mName}}, {{serviceproviders.mContact}}</td>
          <td class="mComments">{{serviceproviders.mComments}}</td>
        </tr>
      </tbody>
    </table>
    <p>&nbsp;</p>
  </div>
</template>


<script>
import json from ".././json/service_providers.json";
export default {
  name: "serviceproviders",
  serviceproviders: json,
};
</script>

<style lang="scss" scoped>
table.providers {
  padding: 6px;
}

h4.centered {
  text-align: center;
}
tbody.sproviders {
  border: 1px solid darkgreen;
  border-collapse: collapse;
}

tr.sp-heading {
  background-color: darkgreen !important;
  color: white;
  vertical-align: top;
  text-align: center !important;
  min-height: 2.2em;
  font-size: 1.1em !important;
  line-height: normal;
}

th.sp-name,
th.service-category,
th.contact-info,
th.member-name,
th.member-comments {
  margin: 12px auto;
  vertical-align: top;
  padding: 18px;
}

tr.mInput > td {
  font-size: 0.8em !important;
  line-height: 1.4em !important;
  margin: 2px 12px;
  padding: 12px !important;
  vertical-align: top;
}

tr.whitebg {
  background-color: white;
}
tr:nth-child(odd) {
  background-color: #e8f7e0;
}

td.sName {
  width: 18%;
  margin-right: 4px;
  font-weight: bold;
  color: darkgreen;
  font-size: 1em !important;
}
td.sCategory {
  width: 15%;
  margin-right: 4px;
}

// SP contact info
td.sContactInfo {
  width: 20%;
  margin-right: 4px;
}

// member Name
td.mName {
  width: 17%;
  margin-right: 4px;
}

//member-comments
td.mComments {
  width: 27%;
  margin-right: 4px;
}

p.smaller {
  font-size: 0.7em;
  text-align: right;
}
</style>
