<template>
    <div class="container">
          <form
          name="add-subscriber"
          id="myForm"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          enctype="application/x-www-form-urlencoded"
          @submit.prevent="handleFormSubmit">
            <input type="hidden" name="form-name" value="add-subscriber" />
            <input type="email" name="userEmail" v-model="formData.userEmail">
            <button type="submit" name="button">Subscribe</button>
          </form>
    </div>
 </template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            formData: {
                userEmail: null,
            },
        }
    },
    methods: {
        encode(data) {  
            const formData = new FormData();
            
            for (const key of Object.keys(data)) {
                formData.append(key, data[key]);
            }
            
            return formData;
        },

        handleFormSubmit(e) {
            const axiosConfig = {
                header: { "Content-Type": "application/x-www-form-urlencoded" }
            };

            axios.post(
                location.href, 
                this.encode({
                    'form-name': e.target.getAttribute("name"),
                    ...this.formData,
                }),
                axiosConfig
            )
            .then(document.getElementById("myForm").innerHTML = `
            <div>
                Thank you! I received your submission.
            </div>
            `)
        }
    }
}
</script>
<style scoped>
label {
  font-weight: bold;
}
input, select, textarea {
    background-color: #3131;
}
</style>