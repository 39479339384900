<script>
import SubHero from "../components/SubHero";
import Officers from "../components/Officers";
export default {
  name: "officers",
  components: {
    SubHero,
    Officers,
  },
};
</script>

<template>
  <div>
    <SubHero />
    <hr />
    <Officers />
  </div>
</template>


<style lang="scss" scoped>
.parkwood {
  .fa-link {
    margin-right: 5px;
  }
}
</style>