<template>
<div class="container">
  <h4 class="centered">Associate Members (Parkwood Ridge & Other)</h4>
        <p class="centered smaller"><a href="/members">Members</a></p>
        <p class="centered smaller">Return to <a href="/member-info">Member Information</a></p>
    <table class="fullsize">
  <tbody class='associates'>
    <tr>
      <th class='family heading'>
        Family
      </th>
      <th class='names heading'>
        Name(s)
      </th>
      <th class='address heading'>
        Address
      </th>
      <th class='phone heading'>
        Phone
      </th>
      <th class='since heading'>
        Since
      </th>
      <th class='email heading'>
        Email
      </th>
  </tr>
  <tr class="whitebg" v-for="members in $options.members" :key="members.id">
		<td class='member'>{{members.member}}</td>
		<td class='names'>{{members.names}}</td>
    <td class='address'>{{members.address}}</td>
    <td class='phone'>{{members.phone}}</td>
    <td class='since'>{{members.since}}</td>
    <td class='email'><a :href="`mailto:${members.email1}`">{{members.email1}}</a><br/> <a :href="`mailto:${members.email2}`">{{members.email2}} </a></td>
  </tr>
  </tbody>
</table>
<p>&nbsp;</p>
</div>
</template>


<script>

import json from '.././json/associates.json'
export default{
  name: "AssociateDirectory",
  members: json
}
</script>

<style lang="scss" scoped>
tbody.associates {
    background-color: darkgreen;
    padding: 12px;
    margin: 0 auto;
}
h4.centered {
  text-align: center;
}

table.fullsize {
  width: 100%;
  border: 1px solid darkgreen;
  border-collapse: collapse;
}
th.heading {
     text-align: left;
     color: white;
     padding-bottom: 1.3em;
} 
tr.whitebg {
  background-color: white;
  line-height: 2em;
}
tr:nth-child(even) { 
  background-color:#d0f0c0; 
} 
td.member{
  width: 15%;
  font-weight: bold;
  color: darkgreen;
  font-size: 1.1em;
  text-indent: .3em;
}
td.names {
  width: 17%;
}
td.address {
  width: 22%;
}
td.phone{
  width: 15%;
}
td.since {
  width: 10%;
}
td.email{
  width: 25%;
}
p.smaller {
  font-size: .8em;
  text-align: right;
}

</style>
