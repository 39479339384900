<template>
  <div class="container">
    <h3 class="alert my-5 text-center">
      This information is for Parkwood members only.
    </h3>
    <p class="alert">Please treat it as confidential.</p>

    <div
      class="d-flex justify-content-center row cols-xs-1 cols-sm-2 cols-md-3"
    >
      <div
        class="card"
        style="width: 16rem; margin: 6px 6px; box-shadow: 0 0 6px darkgreen"
      >
        <div class="card-header" style="font-weight: bold; font-size: 1.2em">
          People & Places
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <a href="/members">Members</a>
          </li>
          <li class="list-group-item">
            <a href="/associates">Associate Members</a>
          </li>
          <li class="list-group-item">
            <a href="/officers">Parkwood Club Board</a>
          </li>
          <li class="list-group-item">
            <a
              href="https://s3.us-west-2.amazonaws.com/parkwoodhomes.org/PlatMap2024Apr.pdf"
              >Lot Map (as of April 2024)</a
            >
          </li>
          <li class="list-group-item">
            <a href="/service-providers">Service Provider Recommendations</a>
          </li>
        </ul>
      </div>

      <div
        class="card"
        style="width: 16rem; margin: 6px 6px; box-shadow: 0 0 6px darkgreen"
      >
        <div class="card-header" style="font-weight: bold; font-size: 1.2em">
          Parkwood Estates
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <a
              href="https://s3-us-west-2.amazonaws.com/parkwoodhomes.org/Parkwood_Covenants_1960.pdf"
              >Protective Covenants</a
            >
          </li>
          <li class="list-group-item">
            <a
              href="https://s3-us-west-2.amazonaws.com/parkwoodhomes.org/Parkwood_ARP_2012_04.pdf"
              >Architectural Review Process</a
            >
          </li>
          <li class="list-group-item">
            <a
              href="https://s3-us-west-2.amazonaws.com/parkwoodhomes.org/LandscapingChangesPolicy_as_of_May2014.pdf"
              >Landscaping Changes</a
            >
          </li>
          <li class="list-group-item">
            <a
              href="https://s3-us-west-2.amazonaws.com/parkwoodhomes.org/ParkwoodEstates_Signs_June26-DRAFT.pdf"
              >Signs (proposed)</a
            >
          </li>
        </ul>
      </div>

      <div
        class="card"
        style="width: 16rem; margin: 6px; box-shadow: 0 0 6px darkgreen"
      >
        <div class="card-header" style="font-weight: bold; font-size: 1.2em">
          Documents & Records
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <a
              href="https://s3-us-west-2.amazonaws.com/parkwoodhomes.org/Parkwood_Bylaws_Amended_22May2013.pdf"
              >Parkwood Club Inc. Bylaws</a
            >
          </li>
          <li class="list-group-item">
            <a
              href="https://s3.us-west-2.amazonaws.com/parkwoodhomes.org/Minutes-2022-Annual-Mtg-Parkwood-Club.pdf"
              >Minutes of Annual Meeting (last update: 2022)</a
            >
          </li>
          <li class="list-group-item">
            <a
              href="https://s3.us-west-2.amazonaws.com/parkwoodhomes.org/FinRptsYE2023.pdf"
              >2023 Financial Report</a
            >
          </li>
          <li class="list-group-item">
            <a
              href="https://s3.us-west-2.amazonaws.com/parkwoodhomes.org/annual-reports/WaAnnualReport2024.pdf"
              >2024 Annual Report</a
            >
          </li>        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MemberInfo",
};
</script>

<style lang="scss" scoped>
p.alert {
  color: red;
  margin-top: -4em;
  font-weight: bold;
  font-style: italic;
  text-align: center;
}
div.block > :first-child {
  display: block;
}
div.padded {
  padding: 10px;
  margin: 12px 4px !important;
}
div.card-header {
  background-color: #e7f7d7 !important;
}
</style>
