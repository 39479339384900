<template>
  <div class="container text-center">
    <div class="col-xs-10 col-md-8 mb-2 mx-auto d-block text-center">
      <h4 class="text-center">Parkwood Club, Inc. is the homeowners' association for the Parkwood community.</h4>
        <span v-if="!$auth.isAuthenticated"> 
        <p class="mt-3">Members, please <button
                id="qsLoginBtn"
                class="btn btn-link tadup"
                @click.prevent="login"
              >log in</button> to access HOA information.
        </p>
        </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeContent",
  methods: {
    login() {
      this.$auth.loginWithRedirect();
    }
  }
};

</script>

<style lang="scss" scoped>
  h2.title {
    color: darkgreen;
    font-size: 2.2em;
    text-align: center;
  }
  p.alert {
    color: red;
    font-weight: bold;
  }
  button.tadup {
    margin-top: -4px;
    background-color:#0c5c05;
    color:#ffffff;
  }
</style>