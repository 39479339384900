<template>
<div class="container">
  <div class="subhero d-flex justify-content-between">
    <img
      class="mb-4 app-logo mx-auto d-none d-sm-block col-xs-4 col-sm-6 col-md-8"
      src="https://s3-us-west-2.amazonaws.com/parkwoodhomes.org/parkwood_v2-logo-small.png"
      alt="Parkwood logo"
    />
  </div>
</div>
</template>

<script>
export default {
  name: "SubHero",
};
</script>

<style lang="scss" scoped>
.app-logo {
  vertical-align: middle;
  padding-top: 12px;
}

.subhero {
  border-radius: 15px;
  box-shadow: 0 0 12px darkgreen;
  background-repeat: no-repeat;
  margin: 0 auto;
  max-width: 30%;
  max-height: 30%;
}
</style>
