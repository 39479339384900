<template>
  <div>
    <SubHero />
    <hr />
    <ServicesForm />
    <br />
  </div>
</template>

<script>
import SubHero from "../components/SubHero";
import ServicesForm from "../components/ServicesForm";

export default {
  name: "contact-us",
  components: {
    SubHero,
    ServicesForm,
  },
};
</script>

<style lang="scss" scoped>
.parkwood {
  .fa-link {
    margin-right: 5;
  }
}
textarea {
  resize: both;
}
</style>
